import brotliPromise from 'brotli-wasm';

export default async function fetchBrotli(jsonUrl) {
  let data = null;
  let error = null;

  try {
    const brotli = await brotliPromise;

    const response = await fetch(jsonUrl);
    const compressedData = new Uint8Array(await response.arrayBuffer());

    const decompressedData = brotli.decompress(compressedData);

    data = JSON.parse(new TextDecoder().decode(decompressedData));
  } catch (err) {
    error = err;
    // eslint-disable-next-line no-console
    console.error(err);
  }

  return { data, error };
}
